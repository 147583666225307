/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.wide {
  width: 100%;
}

.mat-card {
  margin: 2em;
  background-color: whitesmoke;
}

footer,
footer a {
  padding: 1em;
  text-align: center;
  vertical-align: middle;
  width: 90%;
}

@media (min-width: 770px) {

}
